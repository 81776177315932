<template>
  <ion-page>
    <Loading v-if="loading" />
    <div class="container">
      <header class="header">
        <img class="logo" src="@/img/chuteira-bola.svg" />
        <h1 class="h1-color">Recuperar senha</h1>
      </header>
      <div class="container-login">
        <ion-card>
          <div>
            <p class="p-color">
              E-mail
              <ion-icon
                slot="end"
                v-if="emailValidacao"
                color="danger"
                :icon="alertCircle"
                @click="setOpenPopover(true, emailValidacao)"
              ></ion-icon>
            </p>
            <ion-input
              type="email"
              placeholder="Digite seu e-mail"
              :value="email"
              @ionChange="email = $event.target.value"
              @ionFocus="emailValidacao = null"
            ></ion-input>
          </div>
        </ion-card>
        <ion-card>
          <div>
            <p class="p-color">
              Nova senha
              <ion-icon
                slot="end"
                v-if="senhaValidacao"
                color="danger"
                :icon="alertCircle"
                @click="setOpenPopover(true, senhaValidacao)"
              ></ion-icon>
            </p>
            <div style="display: flex; align-items: center; padding-right: 9px">
              <ion-input
                placeholder="Digite sua senha..."
                :type="typeSenha"
                :value="senha"
                @ionChange="senha = $event.target.value"
                @ionFocus="senhaValidacao = null"
              ></ion-input>
              <ion-icon
                style="width: 10%"
                slot="end"
                :icon="eye"
                v-if="typeSenha == 'password'"
                @click="
                  typeSenha = typeSenha == 'password' ? 'text' : 'password'
                "
              ></ion-icon>
              <ion-icon
                style="width: 10%"
                slot="end"
                :icon="eyeOff"
                v-else
                @click="
                  typeSenha = typeSenha == 'password' ? 'text' : 'password'
                "
              ></ion-icon>
            </div>
          </div>
        </ion-card>
        <ion-card>
          <div>
            <p class="p-color">
              Confirmar nova senha
              <ion-icon
                slot="end"
                v-if="senhaConfValidacao"
                color="danger"
                :icon="alertCircle"
                @click="setOpenPopover(true, senhaConfValidacao)"
              ></ion-icon>
            </p>
            <div style="display: flex; align-items: center; padding-right: 9px">
              <ion-input
                placeholder="Confirme sua senha..."
                :type="typeConfSenha"
                :value="senhaConf"
                @ionChange="senhaConf = $event.target.value"
                @ionFocus="senhaConfValidacao = null"
              >
              </ion-input>
              <ion-icon
                style="width: 10%"
                slot="end"
                :icon="eye"
                v-if="typeConfSenha == 'password'"
                @click="
                  typeConfSenha =
                    typeConfSenha == 'password' ? 'text' : 'password'
                "
              ></ion-icon>
              <ion-icon
                style="width: 10%"
                slot="end"
                :icon="eyeOff"
                v-else
                @click="
                  typeConfSenha =
                    typeConfSenha == 'password' ? 'text' : 'password'
                "
              ></ion-icon>
            </div>
          </div>
        </ion-card>
      </div>

      <footer class="footer">
        <ion-button @click="goToLogin()">Login</ion-button>
        <ion-button @click="recover()">Solicitar alteração</ion-button>
      </footer>
    </div>

    <ion-popover
      v-if="!mensagem"
      :is-open="isOpenPopover"
      :event="event"
      :translucent="true"
      @onDidDismiss="setOpenPopover(false)"
    >
      <Popover
        title="Campo inválido"
        type="validation"
        :message="mensagem"
      ></Popover>
    </ion-popover>

    <ion-toast
      :is-open="isOpenToast"
      :color="message.includes('sucesso') ? 'success' : 'danger'"
      :message="message"
      :duration="4000"
      @onDidDismiss="setOpenToast(false)"
    >
    </ion-toast>
  </ion-page>
</template>

<script>
import { IonPage, IonPopover, IonToast } from "@ionic/vue";
import { alertCircle, eye, eyeOff } from "ionicons/icons";
import { useRouter } from "vue-router";
import Popover from "@/components/Popover.vue";
import Loading from "@/components/Layout/Loading.vue";

export default {
  name: "RecoverPassword",
  components: { IonPage, IonPopover, Popover, IonToast, Loading },
  data() {
    return {
      router: useRouter(),
      isOpenToast: false,
      savePassword: false,
      typeSenha: "password",
      typeConfSenha: "password",
      isOpenPopover: false,
      loading: false,
      email: "",
      senha: "",
      senhaConf: "",
      message: "",
      emailValidacao: null,
      senhaValidacao: null,
      senhaConfValidacao: null,
    };
  },
  computed: {
    alertCircle() {
      return alertCircle;
    },
    eye() {
      return eye;
    },
    eyeOff() {
      return eyeOff;
    },
  },
  methods: {
    goToLogin() {
      this.router.replace("/Login");
    },
    validate() {
      const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/i;
      if (!regex.test(this.email))
        this.emailValidacao = "Por favor digite um e-mail válido";
      else this.emailValidacao = null;

      if (!this.senha) this.senhaValidacao = "Por favor digite uma senha";
      else this.senhaValidacao = null;

      if (this.senha.length <= 6)
        this.senhaValidacao = "Por favor digite uma senha mais difícil";
      else this.senhaValidacao = null;

      if (!this.senhaConf)
        this.senhaConfValidacao = "Por favor confirme sua senha";
      else this.senhaConfValidacao = null;

      if (this.senha != this.senhaConf)
        this.senhaConfValidacao = "As senhas não conferem";
      else this.senhaConfValidacao = null;

      return (
        !this.emailValidacao && !this.senhaValidacao && !this.senhaConfValidacao
      );
    },
    setOpenPopover(pState, pMensagem) {
      this.mensagem = pMensagem;
      this.isOpenPopover = pState;
    },
    setOpenToast(pState) {
      this.isOpenToast = pState;
    },
    async recover() {
      if (this.validate()) {
        this.loading = true;
        const response = await this.$store.dispatch("login/recoverPassword", {
          email: this.email,
          novaSenha: this.senha,
        });

        this.message = response;
        this.setOpenToast(true);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
h1 {
  margin: 0;
}

p {
  margin: 0;
  height: 36px;
  color: gray;
}

.header {
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
}

.logo {
  height: 70%;
}

.container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: white;
}

body.dark .container {
  background-color: #121212;
}
.container-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -40px;
}

ion-icon {
  font-size: 25px !important;
}

.container-login ion-card {
  min-height: 72px;
}

ion-label {
  display: flex;
  width: 100%;
  font-weight: bold;
  --color: gray !important;
  padding-bottom: 13px;
  padding-top: 0;
  margin-top: 0;
}

ion-card p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 5px 10px 5px 10px;
  border-bottom: 1px solid gray;
  border-bottom: 1px solid #d9d9d9;
  background-color: #f3f3f3;
}

.footer {
  width: 100%;
  margin-bottom: 10%;
  display: flex;
  justify-content: space-around;
}
</style>
